<template>
  <div class="hello">
    <!--    顶部导航栏-->
    <div class="top-bar">
<!--      <el-menu :background-color="dynamicBackgroundColor" :text-color="textColor" :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">-->
<!--        <div>-->
<!--          &lt;!&ndash;          <img style="width: 107px;&ndash;&gt;-->
<!--          &lt;!&ndash;            height: 31px;" :src="titleImg" />&ndash;&gt;-->
<!--          <img v-if="flag" style="width: 107px; height: 31px;" src= "../assets/title.png" />-->
<!--          <img v-else style="width: 107px;-->
<!--            height: 31px;" src= "../assets/title-B.png" />-->
<!--        </div>-->
<!--        &lt;!&ndash;        <el-menu-item style="font-size: 25px">投融桥</el-menu-item>&ndash;&gt;-->
<!--        <el-menu-item index="1">我是投资方</el-menu-item>-->
<!--        <el-menu-item index="2">-->
<!--          我是项目方-->
<!--        </el-menu-item>-->
<!--        <el-menu-item index="3">我是政府</el-menu-item>-->
<!--        <el-menu-item index="4">登录/注册</el-menu-item>-->
<!--      </el-menu>-->
      <div :style="{background:dynamicBackgroundColor}" :text-color="textColor" :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
        <div style="display:flex;align-items: center;width: 100%;justify-content: space-between;height: 60px">
<!--          <div>-->
            <img v-if="flag" class="img-title"  src= "../assets/title.png" />
            <img class="img-title" v-else  src= "../assets/title-B.png" />
<!--          </div>-->
          <div style="display: flex;margin-right:13%">
            <div class="nav-right" :style="{ color: textColor }" @click="getWindow">我是投资方</div>
            <div  class="nav-right" :style="{ color: textColor }" @click="getWindow">我是项目方</div>
            <div  class="nav-right" :style="{ color: textColor }" @click="getWindow">我是政府</div>
            <div :style="{ color: textColor }" @click="getWindow">登录/注册</div>
          </div>
        </div>
      </div>
      <div class="modal" v-if="showModal">
        <div class="modal-content" style="width: 100%;height: 15%;display: flex;align-items: center;justify-content: center;margin-top: 10px">
<!--          <span class="close" @click="showModal = false">&times;</span>-->
          <div class="modal-text" style="color: #000;background:#FFFFFF;padding: 20px;text-align: center;border-radius: 3px;">正在开发中，敬请期待...</div>
        </div>
      </div>
    </div>
    <!--    轮播图-->
    <div class="swiper">
      <swiper :direction="'vertical'"
              :spaceBetween="0"
              :mousewheel="true"
              :slidesPerView="'auto'"
              @slideChange="onSlideChange"
              :modules="modules">
        <!-- 第一屏 -->
        <swiper-slide>
          <img src="../assets/screen1.png" />
          <div class="relative column creenW">
            <el-image class="title-img" :src="require('../assets/rich-title.png')"></el-image>
            <div class="name">真 实  /  高 效  /  精 准  /  快捷</div>

            <!-- 二维码图片-->
            <div style="display: flex;margin-top: 27px;">
              <div class="column asi-ri">
                <div class="column-img">
                  <el-image  :src="require('../assets/apple-dow.png')" @mouseenter="handleMouseEnter"  @mouseleave="handleMouseLeave"></el-image>
                </div>
                <div  v-if="isCode" class="img-code">
                  <el-image  :src="require('../assets/bg-code.png')"></el-image>
                  <el-image class="img-pos" :src="require('../assets/apple-code.png')"></el-image>
                </div>
                <div v-else class="img-code"></div>
              </div>
              <div class="column">
                <div class="column-img">
                    <el-image :src="require('../assets/android-dow.png')"
                          @mouseenter="enter"  @mouseleave="handleMouseLeave"></el-image>
                </div>
                <div v-if="isCode1" class="img-code" >
                  <el-image :src="require('../assets/bg-code.png')"></el-image>
                  <el-image class="img-pos" :src="require('../assets/android-code.png')"></el-image>
                </div>
              </div>
            </div>
            <div>
              <el-image class="bot-img" :src="require('../assets/bottom.png')"></el-image>
            </div>
            <el-image class="up-img" :src="require('../assets/arrow-bottom.png')"></el-image>



          </div>
        </swiper-slide>

        <!-- 第二屏-->
        <swiper-slide>
          <img src="../assets/bg.png" />
          <swiper-content :isScreen="true" :lookImg="require('../assets/look.png')" richTitle="通过智能匹配技术，快速、高效地连接投资人和项目方，助您轻松实现融资目标"></swiper-content>
        </swiper-slide>

        <!-- 第三屏-->
        <swiper-slide>
          <img src="../assets/screen3.png" />
          <div class="sliderow left">
            <el-image  :src="require('../assets/look1.png')"></el-image>
            <div class="vertical right">
              <el-image  :src="require('../assets/communicate.png')"></el-image>
              <el-image class="img1"  :src="require('../assets/title2.png')"></el-image>
              <div class="right-title">在线沟通</div>
              <div class="right-rich">提供安全的在线聊天功能可以和投资人进行高效的
                沟通和交流
              </div>
            </div>
            <div class="column-pos">
              <div class="column left1">
                <el-image class="bot-img" :src="require('../assets/bottom.png')"></el-image>
                <el-image class="up-img" :src="require('../assets/arrow-bottom.png')"></el-image>
              </div>
            </div>
          </div>
        </swiper-slide>

        <!--第四屏-->
        <swiper-slide>
          <img src="../assets/screen3.png" />
          <div class="sliderow left">
            <div class="vertical left-aside">
              <el-image  :src="require('../assets/investor.png')"></el-image>
              <el-image  :src="require('../assets/title3.png')"></el-image>
              <div class="right-title">智能匹配</div>
              <div class="right-rich aliLeft">智能匹配系统能够根据您的项目需求和投资人的偏好进行精准对接，可以大大提高融资成功的几率</div>
            </div>
            <el-image class="img-right" :src="require('../assets/look2.png')"></el-image>
            <div class="column-pos">
              <div class="column left1">
                <el-image class="bot-img" :src="require('../assets/bottom.png')"></el-image>
                <el-image class="up-img" :src="require('../assets/arrow-bottom.png')"></el-image>
              </div>
            </div>
          </div>
        </swiper-slide>

        <!-- 第五屏-->
        <swiper-slide>
          <img src="../assets/screen3.png" />
          <div class="sliderow left">
            <el-image  :src="require('../assets/look5.png')"></el-image>
            <div class="vertical right">
              <el-image  :src="require('../assets/rich-title5.png')"></el-image>
              <el-image class="img1"  :src="require('../assets/title5.png')"></el-image>
              <div class="right-title">数据分析</div>
              <div class="right-rich aliLeft">提供项目展示和沟通的统计数据，帮助用户优化展示策略</div>
            </div>
            <div class="column-pos">
              <div class="column left1">
                <el-image class="bot-img" :src="require('../assets/bottom.png')"></el-image>
                <el-image class="up-img" :src="require('../assets/arrow-bottom.png')"></el-image>
              </div>
            </div>
          </div>
        </swiper-slide>

        <!--第六屏-->
        <swiper-slide>
          <img src="../assets/screen3.png" />
          <div class="sliderow left">
            <div class="vertical left-aside">
              <el-image  :src="require('../assets/rich-title6.png')"></el-image>
              <el-image class="img1"  :src="require('../assets/title6.png')"></el-image>
              <div class="right-title">项目展示</div>
              <div class="right-rich aliLeft">线上平台发布项目信息，突出展示项目亮点来吸引投资人的关注</div>
            </div>
            <el-image  :src="require('../assets/look6.png')"></el-image>
            <div class="column-pos">
              <div class="column left1">
                <el-image class="bot-img" :src="require('../assets/bottom.png')"></el-image>
                <el-image class="up-img" :src="require('../assets/arrow-bottom.png')"></el-image>
              </div>
            </div>
          </div>
        </swiper-slide>

        <!-- 第七屏-->
        <swiper-slide>
          <img src="../assets/screen3.png" />
          <div  class="relative column asiText">
            <el-image class="img-box" :src="require('../assets/title7.png')"></el-image>
            <el-text>听听我们的用户怎么说，他们通过投融桥APP获得了哪些成功经验和帮助  </el-text>
            <div class="screen-one">
              <el-image v-for="(item,index) in screenImg" :key="'item-' + index" class="screen-img" :src="item.img"></el-image>
            </div>
            <div class="image">
              <el-image  style="margin-top: -14px" :src="require('../assets/pic1.png')"></el-image>
              <el-image  class="screen-img1" :src="require('../assets/pic2.png')"></el-image>
<!--              <el-image v-for="(item,index) in screenImg1" :key="'item-' + index" class="screen-img1" :src="item.img"></el-image>-->
            </div>
            <div class="image sec">
              <el-image  class="screen-img2" :src="require('../assets/pic3.png')" ></el-image>
              <el-image  class="screen-img3" :src="require('../assets/pic4.png')" ></el-image>
            </div>
<!--            <div class="image">-->
<!--              <el-image  class="screen-img2" :src="require('../assets/pic4.png')" ></el-image>-->
<!--            </div>-->
<!--            <div class="column-pos">-->
<!--              <div class="column left1">-->
<!--                <el-image class="bot-img" :src="require('../assets/bottom.png')"></el-image>-->
<!--                <el-image class="up-img" :src="require('../assets/arrow-bottom.png')"></el-image>-->
<!--              </div>-->
<!--            </div>-->
          </div>
        </swiper-slide>

        <!-- 页尾-->
        <swiper-slide>
          <img src="../assets/bottom-bg.png" />
          <div class="sliderow edge">
            <div>
              <div class="botom-box">
                <el-image class="botom-img" :src="require('../assets/logo.png')"></el-image>
                <div class="bottom-text">投融桥App</div>
              </div>
              <!-- 二维码图片-->
              <div style="display: flex;margin-top: 16px">
                <div class="column asi-ri">
                  <div class="column-img">
                    <el-image :src="require('../assets/apple-dow.png')"
                              @mouseenter="handleMouseEnter"  @mouseleave="handleMouseLeave"></el-image>
                  </div>
                  <div v-if="isCode" class="img-code">
                    <el-image   :src="require('../assets/bg-code.png')"></el-image>
                    <el-image class="img-pos" :src="require('../assets/apple-code.png')"></el-image>
                  </div>
                  <div v-else class="img-code"></div>
                </div>
                <div class="column">
                  <div class="column-img">
                    <el-image  :src="require('../assets/android-dow.png')"
                              @mouseenter="enter"  @mouseleave="handleMouseLeave"></el-image>
                  </div>

                  <div v-if="isCode1" class="img-code">
                    <el-image   :src="require('../assets/bg-code.png')"></el-image>
                    <el-image class="img-pos" :src="require('../assets/android-code.png')"></el-image>
                  </div>
                </div>
              </div>
            </div>
            <div class="vertical left-aside" style="margin-left: -300px">
              <div class="icon-title">联系方式</div>
              <div v-for="(item,index) in iconList" :key="index">
                <div class="botom-box">
                  <el-image :src="item.img"></el-image>
                  <el-text>{{ item.name }}</el-text>
                </div>
              </div>
            </div>
            <div class="bot-txt">©2019 投融桥 版权所有 备案号：<a href="https://beian.miit.gov.cn/" target="_blank" style="color: #fff;text-decoration: none;">京ICP备2024070727号-1</a></div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script setup>
import swiperContent from  "../components/swiperContent.vue"
import {Swiper, SwiperSlide} from "swiper/vue";
import { Mousewheel, Pagination } from 'swiper/modules'
import {onMounted, ref} from "vue";
import 'swiper/css'
// import 'swiper/css/pagination';

const modules = [Mousewheel, Pagination]
const isCode = ref(false);
const isCode1 = ref(false);
// const titleImg = ref(require('../assets/communicate.png')) //标题图片
// const richImg = ref(require('../assets/title2.png')) //副标题图片
const flag = ref(true);//图标
const dynamicBackgroundColor = ref('transparent');//背景颜色
const textColor = ref('#fff');//字体颜色
const iconList = ref([
  {img:require('../assets/name-icon.png'),name:"公司名称：中投协新兴（北京）投资咨询顾问有限公司\n"},
  {img:require('../assets/work-icon.png'),name: "工作时间：周一至周五 9:00-18:00\n"},
  {img:require('../assets/qq-icon.png'),name: "企业邮箱：tourongqiaoapp@163.com\n"},
  {img:require('../assets/address-icon.png'),name:  "地址：北京市复兴路2号院\n"},
])

const screenImg = ref([
  {img:require('../assets/case-01.png')},
  {img:require('../assets/case-02.png')},
  {img:require('../assets/case-03.png')},
])

const showModal = ref(false);
const timeout = ref(null);
// const screenImg1 = ref([
//   {img:require('../assets/pic1.png')},
//   {img:require('../assets/pic2.png')},
// ],)
// const screenImg2 = ref([
//   {img:require('../assets/pic3.png')},
//   {img:require('../assets/pic4.png')},
// ])

onMounted(()=>{
  // let t = window.devicePixelRatio;
  // console.log(t)
  // if (!!window.ActiveXObject || "ActiveXObject" in window) {
  //   if (t != 1) {
  //     // 如果在笔记本中用IE浏览器打开 则弹出提示
  //     alert('您的设备对显示进行放大导致页面显示不完全,请调整后打开/或用其他浏览器')
  //   }
  // } else {
  //   if (t != 1) {   // 如果进行了缩放，也就是不是1
  //
  //     document.querySelector('.creenW').style.height = '100%';
  //
  //     document.querySelector('.img-box').style.width = '50%';
  //       document.querySelector('.screen-one').style.height = '80%';
  //
  //   }
  // }
})

// 鼠标移入按钮
const handleMouseEnter = () => {
  isCode.value = true;
};

const enter = () => {
  isCode1.value = true;
};

//鼠标移出按钮
const handleMouseLeave = () => {
  isCode.value = false;
  isCode1.value = false;
};

const getWindow = () =>{
  showModal.value = true;
      if (timeout.value) clearTimeout(timeout.value);
      timeout.value = setTimeout(() => {
        showModal.value  = false;
      }, 2000); // 弹窗显示3秒后消失
}

// const aa = () =>{
//   showModal.value = false;
// }
// const closeModal = () => {
//   setTimeout(getWindow, 500);
// };

const onSlideChange = (e)=>{
  if(e.activeIndex !== 0){
    dynamicBackgroundColor.value = "#fff";
    textColor.value = "#000";
    flag.value = false
  }else{
    dynamicBackgroundColor.value = 'transparent';//背景颜色
    textColor.value = '#fff';//字体颜色
    flag.value = true
  }
}
</script>

<style scoped lang="less">

@media screen and (max-width: 1287px) {
  .image {
    display: none;
  }
  //.aa{
  // display: none;
  //}
}
//@media screen and (max-device-width:1280px){

@media screen and (max-height: 900px) {
  //.el-image{
  //  height: 90%;
  //}
  .look-img{
    height: 70%;
  }
  .asiText{
    height: 90%;
    top:100px;
    .screen-img{
      width: 400px;
      height: 330px;
    }

    .screen-img1{
      width: 586px;
    }
    //.image{
      .screen-img2{
        width: 592px;
        //height: 141px
      }
    //}

  }
  .creenW{
    height: 90%;
  }
  .el-image__inner{
    height: 90%;
  }
}
@media screen and (max-height: 800px) {
  .el-image{
    height: 88%;
  }
  .look-img{
    height: 70%;
  }

  .asiText{
    height: 90%;
    top:100px;
    .img-box{
      width: 58%;
      height: 8%;
      margin-top: 20px;
    }
    .screen-img{
      width: 358px;
      //height: 310px;
    }
    .screen-img1{
      width: 566px;
      height: 145px;
      //height: 121px
    }
    .sec{
      margin-top: -48px;
      .screen-img2{
        width: 522px;
        //height: 141px
      }
    }

  }

  .img-code {
    .img-pos {
      width: 143px;
      height: 143px;
      position: absolute;
      margin-left: -145px;
      margin-top: 10px;
    }
  }
  .creenW{
    height: 80%;
    .title-img{
      margin-top: 100px;
    }
  }
  .el-image__inner{
    height: 50%;
  }
}

//点击完背景颜色
//.el-menu--horizontal>.el-menu-item.is-active{
//  color: #fff !important;
//  background-color: transparent !important;
//  //border-bottom: none !important;
//}
////导航栏背景颜色
//.el-menu-item:hover{
//  background-color: transparent !important;
//}
.aa{
  display: flex;justify-content: center
}
.nav-right{
  margin-right: 47px;
}
.top-bar{
  position:absolute;
  top:0;
  width: 100%;
  z-index: 999
}
.img-title{
  width: 107px; height: 31px;
  margin-left: 18%
}
.swiper {
  height: 100vh;
}
.swiper-slide{
  position: relative;
}
.swiper-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  //background-size: cover;
}
.swiper-slide:nth-child(8) {
  height: 450px;
}
.column-pos{
  position:absolute;
  right: 0
}
.column{
  display: flex;
  align-items: center;
  flex-direction: column;
  .column-img{
    width: 181px;
    height: 66px;
  }
}
.asi-ri{
  margin-right: 34px;
}
.screen-img{
  width: 424px;
  height: 379px;
}
.screen-img1{
  width: 636px;
  height: 180px;
  margin-top: -20px;
}
.screen-img2{
  width: 636px;
  margin-top: -40px;
  //height: 189px;
}
.vertical{
  display: flex;
  flex-direction: column;
  .el-img{
    width: 620px;
    height: 62px;
  }
  .img1{
    width: 435px;
    height: 70px;
    margin-top: -15px;
  }
  .right-title{
    font-weight: 600;
    font-size: 36px;
    color: #0D69EB;
    margin: 29px 0 39px;
  }
  .right-rich{
    font-size: 22px;
    color: #555555;
    width: 494px;
    text-align: right;
    line-height: 35px;
  }
  .img-right{
    width: 600px;height: 1001px;
    margin-right: 150px
  }
  .aliLeft{
    text-align: left;
  }
}
.right{
  align-items: flex-end;
  margin-right: 240px;
}
.left-aside{
  align-items: flex-start;
}
.relative{
  position: absolute;
  width: 100%;
  height: 110%;
  justify-content: center;
  top:0
}
.asiText{
  //margin-left: -250px;
  .el-img{
    width: 861px;
    height: 94px;
  }
  .img-box{
    width: 840px;
  }
  .el-text{
    font-size: 26px;
    color: #409EFF;
    margin: 10px 0;
  }
}
.title-img{
  width: 1209px;
  height: 122px;
  margin-top: 200px;
}
.name{
  color: #fff;
  font-size: 34px;
  letter-spacing: 4px;
}

.img-code{
  width: 164px;
  height: 171px;
  margin-top:17px;

}
.img-pos{
  width: 150px;
  height: 150px;
  position: absolute;
  margin-left: -157px;
  margin-top: 15px
}
.up-img{
  width: 22px;height: 35px;
  animation:init 2s ease-in infinite;
  margin-top: 46px;
}
@keyframes init {
  0%, 100% {
    transform: translateY(0);
    animation-timing-function: cubic-bezier(.8,0,1,1);
  }
  50% {
    transform: translateY(20%);
    animation-timing-function: cubic-bezier(0,0,.2,1);
  }
}
.sliderow{
  width: 100%;
  display: flex;
  position:absolute;
  top:0px;
  align-items: center;
}
.edge{
  border-bottom: 2px solid #313132;
  width: 100%;
  justify-content: space-around;
  padding: 31px 0 21px;
  margin-right: 120px;
  //margin-left: -150px;
}
.aside{
  flex: 1;
  .el-img{
    width: 600px;height: 1001px
  }
}
.left{
  width: 100%;
  height: 110%;
  justify-content: center;
  .el-img{
    width: 664px;
    height: 680px;
  }
  .screen-list{
    display: flex;
    flex-direction: column;
    margin-left: 87px;
    margin-right: 150px;
    .list-richImg{
      width: 410px;
      height: 169px;
    }
    .list-img{
      width: 356px;
      height: 34px;
      margin: 47px 0 32px;
    }
    .list-text{
      font-size: 20px;
      color: #333333;
      width: 450px;
      text-align: left;
      line-height: 30px;
    }
  }
}
.left1{
  margin-right: 56px;
}
.bot-img{
  width: 32px;height: 56px
}
.icon-title{
  font-weight: 600;
  font-size: 20px;
  color: #FFFFFF;
  margin-bottom: 24px
}
.botom-box{
  display: flex;
  align-items: center;
  .botom-img{
    width: 69px;
    height: 70px;
  }
  .bottom-text{
    font-size: 20px;
    color: #FFFFFF;
    margin-left: 15px
  }
  .el-img{
    width: 30px;
    height: 30px;
  }
  .el-text{
    font-size: 14px;
    color: #CCCFD1;
    line-height: 40px;
    margin-left: 10px
  }
}
.el-menu--horizontal.el-menu{
  border: none;
}
.el-menu--horizontal>.el-menu-item{
  font-size: 16px;
}
.bot-txt{
  position: absolute;
  bottom: -35px;
  font-size: 14px;
  color: #C4C7C9;
}
</style>
